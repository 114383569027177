const GALLERY_STATE = {
    gallery: {
        index: null,
        items: [],
        options: {},
        autoOpenYT: false
    }
};
//
const GALLERY_MUTATIONS = {
    // eslint-disable-next-line no-unused-vars
    resetGallery: ( { gallery } ) => {
        console.log('resett');
        gallery.index = null;
        gallery.items = [];
        gallery.options = {};
        gallery.autoOpenYT = false;
    },
    openGallery: ( { gallery }, payload ) => {
        gallery.index = payload.index;
        gallery.items = payload.items;
        gallery.options = payload.options;
        gallery.autoOpenYT = payload.autoOpenYT;
    }
};

const GALLERY_ACTIONS = {
    openGallery: ({ commit }, payload ) => {
        commit('openGallery', payload );
    }
};

const GALLERY_GETTERS = {
    //state
    galleryIndex: (state) => state.gallery.index,
    galleryItems: (state) => state.gallery.items,
    galleryOptions: (state) => state.gallery.options,
    galleryAutoOpenYT: (state) => state.gallery.autoOpenYT,
};


export { GALLERY_STATE, GALLERY_GETTERS, GALLERY_ACTIONS, GALLERY_MUTATIONS }

