const LOADER_STATE = {
    loader: {
        active: false,
        showAnimation: false,
    }
};
//
const LOADER_MUTATIONS = {
    loaderActive: ( { loader } ) => {
        loader.active = true;
    },
    loaderDeactive: ( { loader } ) => {
        loader.active = false;
    },
    loaderShowAnimationActivation: ( { loader } ) => {
        loader.showAnimation = true;
    },
    loaderShowAnimationDeactivation: ( { loader } ) => {
        loader.showAnimation = false;
    },
};

const LOADER_ACTIONS = {
    makeLoaderActive( ){ //{ commit }
        // commit('loaderActive');
        // setTimeout( () => {
        //     commit('loaderShowAnimationActivation');
        // }, 100 );
        //
        // // eslint-disable-next-line no-unused-vars
        // return new Promise( (resolve, reject ) => {
        //     setTimeout( () => {
        //         return resolve();
        //     }, 800 );
        // })
    },
    makeLoaderInactive(  ){ //{ commit }
        // commit('loaderShowAnimationDeactivation');
        //
        // // eslint-disable-next-line no-unused-vars
        // return new Promise( (resolve, reject ) => {
        //     setTimeout( () => {
        //         commit('loaderDeactive');
        //         return resolve();
        //     }, 800 );
        // })
    }
};

const LOADER_GETTERS = {
    isLoaderActive: state => state.loader.active,
    isLoaderShowAnimationActive: state => state.loader.showAnimation,

};


export { LOADER_STATE, LOADER_GETTERS, LOADER_ACTIONS, LOADER_MUTATIONS }

