const LANG_STATE = {
    lang: 'de'
};
//
const LANG_MUTATIONS = {
    setDeutsch: ( state ) => {
        state.lang = 'de'
    },
    setEnglish: ( state ) => {
        state.lang = 'en'
    },
};

const LANG_ACTIONS = {
};

const LANG_GETTERS = {
    getLang: (state) => state.lang,
};


export { LANG_STATE, LANG_GETTERS, LANG_ACTIONS, LANG_MUTATIONS }

