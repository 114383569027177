const routes = [
    {
        path: '/',
            name: 'Start',
        component: () => import(/* webpackChunkName: "store" */ '../views/StartView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/store',
            name: 'Store',
        component: () => import(/* webpackChunkName: "store" */ '../views/StoreView.vue'),
        meta: { lang: 'de' }

    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: { lang: 'de' }

    },
    {
        path: '/sprechstunde',
        name: 'Sprechstunde',
        component: () => import(/* webpackChunkName: "about" */ '../views/SprechstundeView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: () => import(/* webpackChunkName: "about" */ '../views/DownloadsView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/kaufen',
        name: 'Buy',
        component: () => import(/* webpackChunkName: "about" */ '../views/BuyView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/KontaktView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/termine',
        name: 'Termine',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/EventsView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/ImpressumView.vue'),
        meta: { lang: 'de' }
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: () => import(/* webpackChunkName: "datenschutz" */ '../views/DatenschutzView.vue'),
        meta: { lang: 'de' }
    },
];

export default routes;
