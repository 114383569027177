<template>
    <div :class="{ 'menuButtonGB': getLang === 'en'}" id="menuButton" @click="_openMenu"></div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'MenuButtonComponent',
        computed:{
            ...mapGetters(['isMenuOpen', 'getLang'])
        },
        methods: {
            ...mapMutations(['openMenu']),
            _openMenu(){
               this.openMenu();
            }
        }
    }

</script>

<style lang="less" scoped>

    #menuButton{
        position: absolute;
        top: 28px;
        right: 28px;

        width: 199px;
        height: 106px;

        background: url('../assets/images/menu-button.jpg') center center no-repeat;

        z-index: 99;

        cursor: pointer;

        transform: scale(0.8);
        transition: 0.3s all ease-in-out;

        &:hover{
            transform: scale(0.9);
        }

        &.menuButtonGB{
            background-image: url('../assets/images/menu-button-gb.jpg') !important;
        }
    }

</style>
