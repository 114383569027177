import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';

import DeutschRoutes from './de';
import EnglishRoutes from './en';

Vue.use(VueRouter)
Vue.use(VueMeta);


const routes = [
  ...DeutschRoutes,
  ...EnglishRoutes,
  {
    path: '/wp-admin',
    name: '',
    redirect: () => {
      window.location.href = '/wp/wp-admin'
      return '/wp/wp-admin' // not important since redirecting
}
  },
  {
    path: '*',
    name: '',
    redirect: { name: 'Start'}
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


export default router
