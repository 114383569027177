<template>
    <div id="loader" v-if="isLoaderActive" :class="{ 'animation show': isLoaderShowAnimationActive }">
        <img src="../assets/images/remedy-logo-big.png" alt="Logo Remedy Rum">
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default{
        name: 'LoaderComponent',
        computed:{
            ...mapGetters(['isLoaderActive', 'isLoaderShowAnimationActive'])
        }
    }
</script>

<style lang="less" scoped>
    
    #loader{
        position: absolute;
        height: 100%;
        width: 100%;

        background: #f8ede1;

        z-index: 9999;

        display: flex;
        justify-content: center;
        align-items: center;

        opacity: 0;

        transition: 0.4s all ease-in-out;

        &.animation.show{
            opacity: 1;
        }

        img{
            max-width: 90%;
            max-height: 90%;
            width: auto;
            height: auto;
        }
    }
    
</style>
