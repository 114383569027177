<template>
  <div id="app">
    <LoaderComponent></LoaderComponent>
    <LangSwitcherComponent></LangSwitcherComponent>
    <MenuButtonComponent></MenuButtonComponent>
    <MenuContentComponent></MenuContentComponent>
    <AgeQuestionComponent></AgeQuestionComponent>
    <CookiesComponent></CookiesComponent>
    <gallery :images="galleryItems" :index="galleryIndex" :options="galleryOptions" @close="resetGallery" @onslide="slideGallery" ></gallery>
    <router-view/>
  </div>
</template>

<script>
  import VueGallery from 'vue-gallery';
  import AgeQuestionComponent from './components/AgeQuestionComponent';
  import MenuButtonComponent from './components/MenuButtonComponent';
  import MenuContentComponent from './components/MenuContentComponent';
  import LoaderComponent from './components/LoaderComponent';
  import LangSwitcherComponent from './components/LangSwitcherComponent'

  import { mapGetters, mapMutations } from 'vuex';
  import CookiesComponent from "./components/CookiesComponent";

  export default {
    name: 'App',
    metaInfo: {
      title: 'Start',
      titleTemplate: '%s | Remedy'
    },
    components: {
      CookiesComponent,
      AgeQuestionComponent,
      MenuButtonComponent,
      MenuContentComponent,
      LoaderComponent,
      LangSwitcherComponent,
      'gallery': VueGallery
    },
    computed: {
      ...mapGetters(['galleryIndex', 'galleryItems', 'galleryOptions', 'galleryAutoOpenYT', 'getLang'])
    },
    methods: {
      ...mapMutations(['resetGallery']),
      slideGallery(){
        if( this.galleryAutoOpenYT ){
          const gallery = this.$el.getElementsByClassName('video-content');
          gallery[0].childNodes[2].click();
        }
      }
    }
  }
</script>

<style lang="less">
  @import "../node_modules/bootstrap/dist/css/bootstrap-grid.min.css";
  @import "assets/less/styles";
</style>
