const AGEQUESTION_STATE = {
    ageQuestion: {
        show: false,
        confirmed: ( localStorage.getItem('ageConfirmed') == true ? true : false ),
        hide: false
    }
};
//
const AGEQUESTION_MUTATIONS = {
    showAgeQuestion: ( { ageQuestion } ) => {
        ageQuestion.show = true;
    },
    hideAgeQuestion: ( { ageQuestion } ) => {
        ageQuestion.show = false;
    },
    confirmAgeQuestion: ( { ageQuestion } ) => {
        ageQuestion.confirmed = true;
    },
};

const AGEQUESTION_ACTIONS = {
    confirmAge: ( state ) => {
        localStorage.setItem( 'ageConfirmed', true );
        state.commit('confirmAgeQuestion');

        setTimeout( () => {
            state.commit('hideAgeQuestion');
        }, 400)
    }
};

const AGEQUESTION_GETTERS = {
    ageQuestionShowState: state => state.ageQuestion.show,
    ageQuestionIsConfirmed: state => state.ageQuestion.confirmed
};


export { AGEQUESTION_STATE, AGEQUESTION_GETTERS, AGEQUESTION_ACTIONS, AGEQUESTION_MUTATIONS }

