<template>
    <div id="ageQuestionBg" :class="{ 'show': (ageQuestionShowState && !ageQuestionIsConfirmed), 'animation': ageQuestionIsConfirmed, 'hide': (!ageQuestionShowState && ageQuestionIsConfirmed) }">
        <div id="ageQuestion" >
            <div id="ag-line"></div>
            <div id="ag-content" class="">
                <div>
                    <div v-if="isNegative" id="ag-negative">Um diese Website betreten zu können müssen Sie bestätigen, dass Sie mindestens 18 Jahre alt sind.</div>
                    <div v-if="!isNegative" style="text-align: center"><img src="../assets/images/sind-sie.png" alt=""></div>
                    <div v-if="!isNegative" class="answer">
                        <div @click="confirm" class="ja active"></div>
                        <div @click="unconfirm" class="nein"></div>
                    </div>
                </div>
            </div>
            <div id="ag-footer"></div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'AgeQuestionComponent',
        data(){
            return {
                jaObj: '',
                neinObj: '',
                isNegative: false
            }
        },
        mounted() {
            this.getObjNein();
            this.getObjJa();

            this.neinObj.addEventListener(
                'mouseover', () => {
                    this.jaObj.classList.remove('active');
                }
            );

            this.neinObj.addEventListener(
                'mouseleave', () => {
                    this.jaObj.classList.add('active');
                }
            )
        },
        computed: {
            ...mapGetters(['ageQuestionShowState', 'ageQuestionIsConfirmed'])
        },
        methods: {
            ...mapActions(['confirmAge']),
            ...mapMutations(['hideAgeQuestion']),
            getObjNein(){
                this.neinObj    = this.$el.getElementsByClassName('nein');
                this.neinObj    = this.neinObj[0];
            },
            getObjJa(){
                this.jaObj      = this.$el.getElementsByClassName('ja');
                this.jaObj      =  this.jaObj[0];
            },
            confirm(){
                this.confirmAge();
            },
            unconfirm(){
                this.isNegative = true;
            }
        }
    }
</script>

<style lang="less" scoped>
    @ratio: 1;

    #ageQuestionBg{
        position: absolute;

        width: 100vw;
        height: 100vh;

        z-index: -1;
        background-color: rgba(0,0,0, 0.0);
        transition: 0.3s background-color ease-in-out;

        &.hide{
            z-index: -1;
            background-color: rgba(0,0,0, 0.0);
            display: none;
        }

        &.animation{
            z-index: 9;
            background-color: rgba(0,0,0, 0.55);

            #ageQuestion{
                transform: translateY(-100%);

                transition: 0.4s all ease-in-out;
            }
        }

        &.show{
            z-index: 9;
            background-color: rgba(0,0,0, 0.55);

            #ageQuestion{
                opacity: 1;
                transform: translateY(0px);

                #ag-content{

                        -webkit-transform-origin: top center;
                        transform-origin: top center;
                        -webkit-animation-name: swing;
                        animation-name: swing;


                        -webkit-animation-duration: 1.5s;
                        animation-duration: 1.5s;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;
                        -webkit-animation-delay: 0.2s;
                        animation-delay: 0.2s;
                }

                #ag-footer{
                    transform: translateY(0px);
                }

            }
        }

    }



    #ageQuestion{
        @width: 762px * @ratio;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 10;

        width: @width;
        margin-left: -(@width/2);
        margin-top: -100px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        opacity: 0;
        transform: translateY(-200px);

        transition: 1.6s transform ease-in-out;
    }

    #ag-negative{
        font-family: 'docktrinregular';
        font-size: 26px;
        text-align: center;
    }

    #ag-line{
        @width: 762px * @ratio;
        @height: 283px * @ratio;

        width: @width;
        height: @height;

        background: url(../assets/images/ag-line.png) center center no-repeat;
        background-size: cover;
    }

    #ag-content{
        position: relative;
        @width: 762px * @ratio;
        @height: 412px * @ratio;

        width: @width;
        height: @height;

        padding: 115px * @ratio;

        background: url(../assets/images/ag-content.jpg) center center no-repeat;
        background-size: cover;

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    #ag-footer{
        position: relative;
        @width: 526px * @ratio;
        @height: 161px * @ratio;

        width: @width;
        height: @height;

        background: url(../assets/images/ag-footer.png) center center no-repeat;
        background-size: cover;

        transform: translateY(-180% * @ratio);

        transition: 0.8s 1.1s all ease-in-out;

        z-index: 1;
    }

    .answer{
        @width: 144px * @ratio;
        @height: 60px * @ratio;

        display: flex;
        justify-content: center;

        padding-top: 45px;

        .ja, .nein{
            width: @width;
            height: @height;

            position: relative;

            cursor:pointer;
        }

        .ja{
            background: url(../assets/images/ja.png) center center no-repeat;

            cursor: url('../assets/images/cursor-ja.png')  112 0, pointer;

            &.active{
                &::before{
                    left: 0px
                }
            }

            &::before{
                position: absolute;
                top: 0px;
                left: @width;

                width: @width;
                height: @height;

                display: block;

                content: ' ';

                background: url(../assets/images/border-answer.png) center center no-repeat;
                background-size: cover;

                transition: 0.3s all ease-in-out;
            }
        }

        .nein{
            background: url(../assets/images/nein.png) center center no-repeat;

            cursor: url('../assets/images/cursor-nein.png'), pointer;
        }

    }

    .make(){
        from{
            -webkit-transform: rotate3d(0, 0, 1, 0) translateY(-100px);
            transform: rotate3d(0, 0, 1, 0) translateY(-100px);
        }

        50% {
            -webkit-transform: rotate3d(0, 0, 1, -10deg) translateY(-60px);
            transform: rotate3d(0, 0, 1, -10deg) translateY(-60px);
        }

        to {
            -webkit-transform: rotate3d(0, 0, 1, 0deg) translateY(0px);
            transform: rotate3d(0, 0, 1, 0deg) translateY(0px);
        }
    }
    @-webkit-keyframes swing {
        .make();
    }

    @keyframes swing{
        .make();
    }

</style>
