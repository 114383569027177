
<template>
    <div id="menuContent" :class="{'opened': isMenuOpen}">
        <div id="menu">
            <div id="menu-content-close" @click="closeMenu"></div>
            <div id="menu-content-top"></div>
            <div id="menu-content-bottom"></div>
            <div id="menu-title">{{ getLang === 'de' ? 'Menü' : 'Menu'}}</div>
            <div id="menu-crafted"></div>
            <perfect-scrollbar class="menu-content-mid" style="height: 100%">
                <ul class="menu-top">
                   <router-link v-for="item in menu[getLang]" :key="item.title" tag="li" :to="item.to" @click.native="_closeMenu">{{ item.title }}</router-link>
                </ul>
                <div class="text-center"><img src="../assets/images/menu-seperator.png" alt=""></div>
                <ul class="menu-bottom">
                    <router-link v-for="item in menu2[getLang]" :key="item.title" tag="li" :to="item.to" @click.native="_closeMenu">{{ item.title }}</router-link>
                </ul>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'MenuContentComponent',
        data(){
            return {
                menu: {
                    de: [
                        {  title: 'store', to: { name: 'Store' } },
                        {  title: 'About remedy', to: { name: 'About' } },
                        {  title: 'Sprechstunde', to: { name: 'Sprechstunde'} },
                        {  title: 'kaufen', to: { name: 'Buy'} },
                        {  title: 'Termine', to: { name: 'Termine'} },
                        {  title: 'Downloads', to: { name: 'Downloads'} },
                        {  title: 'kontakt', to: { name: 'Kontakt'} },
                    ],
                    en: [
                        {  title: 'store', to: { name: 'StoreENG' } },
                        {  title: 'About remedy', to: { name: 'AboutENG' } },
                        {  title: 'Consultation Hours', to: { name: 'SprechstundeENG'} },
                        {  title: 'BUY NOW', to: { name: 'BuyENG'} },
                        {  title: 'Events', to: { name: 'EventsENG'} },
                        {  title: 'Downloads', to: { name: 'DownloadsENG'} },
                        {  title: 'Contact', to: { name: 'KontaktENG'} },
                    ]
                },
                menu2: {
                    de: [
                        {  title: 'Impressum', to: { name: 'Impressum'} },
                        {  title: 'Datenschutz', to: { name: 'Datenschutz'} },
                    ],
                    en: [
                        {  title: 'Imprint', to: { name: 'ImpressumENG'} },
                        {  title: 'Data Privacy', to: { name: 'DatenschutzENG'} },
                    ]
                }
            }
        },
        computed:{
            ...mapGetters(['isMenuOpen', 'getLang'])
        },
        methods: {
            ...mapMutations(['closeMenu']),
            _closeMenu(){
                this.closeMenu();
            },
        }
    }

</script>

<style lang="less" >
    @import "../assets/less/vars";

    @width: 416px;
    @padding: 137px;

    #menuContent{
        position: absolute;
        top: 0;
        right: 0;

        width:  @width;
        height: 100vh;

        background: url('../assets/images/menu-content-bg.jpg') top center repeat-y;

        z-index: 999;

        transform: translateX(  @width );

        transition: 0.7s all ease-in-out;

        &.opened{
            transform: translateX(0px);
        }
    }

    #menu {
        position: relative;
        height: 100%;
        width: 100%;

        padding-top: @padding ;
        padding-bottom: @padding;

        padding-left: 35px;
        padding-right: 35px;
    }

    .menu-content-mid{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &.ps--active-y{
            .ps__rail-y{
                opacity: 1 !important;
            }
        }

        .menu-top{
            padding: 15px 0px 0px 0px;
            margin: 0;

            li{
                font-family: 'docktrinregular';

                margin-bottom: 15px;

                font-size: 43px;
                color: @themeColor1;
                text-align: center;

                line-height: 1.05;

                list-style: none outside;

                cursor:pointer;

                transition: 0.3s all ease-in-out;

                &:hover{
                    color: @themeColor3;
                }
            }

        }

        .menu-bottom{
            padding: 0px;
            margin: 0;

            li{
                padding-top: 2px;
                padding-bottom: 2px;
                
                font-family: 'Palatino-Roman';

                font-size: 24px;
                color: @themeColor1;
                text-align: center;

                list-style: none outside;

                cursor:pointer;

                transition: 0.3s all ease-in-out;

                &:hover{
                    color: @themeColor3;
                }
            }

        }

    }

    #menu-crafted{
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);


        width: 308px;
        height: 57px;

        z-index: 2;

        background: url("../assets/images/footer-crafted.png") center center no-repeat;
        background-size: cover;


    }

    #menu-content-bottom, #menu-content-top {
        position: absolute;
        width: @width;
        height: @padding;
    }

    #menu-content-top {
        top: 0;
        right: 0;

        background: url('../assets/images/menu-content-top.png') top center no-repeat;
    }

    #menu-title {
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);

        z-index: 2;

        font-family: 'docktrinregular';
        font-size: 30px;
        color: @themeColor3
    }

    #menu-content-bottom {
        bottom: 0;
        right: 0;

        background: url('../assets/images/menu-content-bottom.png') bottom center no-repeat;
    }

    #menu-content-close{
        position: absolute;
        top: 55px;
        right: 58px;

        width: 26px;
        height: 26px;

        background: url('../assets/images/menu-content-close.png') center center no-repeat;

        z-index: 3;

        transition: 0.2s all ease-in-out;

        cursor: pointer;

        &:hover{
            transform: rotate(90deg) scale(1.1);
        }
    }
</style>
