import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// AXIOS
import axios from 'axios'
import vueAxios from 'vue-axios'
axios.defaults.baseURL = 'https://remedy-rum.com/wp/wp-json/remedy/v1/';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const _axios = axios;

Vue.use( vueAxios, axios  );

// WALIDATOR
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


router.beforeEach( (to, from, next) => {
  if (to.matched.some(record => record.meta.lang )) {
    if ( to.matched[0].meta.lang == 'en') {
      store.commit('setEnglish');
      next()
    }else{
      store.commit('setDeutsch');
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});

Vue.config.productionTip = false;

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)


new Vue({
  router,
  store,
  mounted(){
    setTimeout( () => {
      if( !localStorage.getItem('ageConfirmed') ){
        store.commit('showAgeQuestion');
      }
    }, 50);
  },
  render: h => h(App)
}).$mount('#app')
