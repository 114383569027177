<template>
    <div id="cookies-banner-overlay" v-if="!isCookiesAcc">
        <form id="cookies-banner-box" ref="cookieBanner">
            <h3 id="cbb-title">Datenschutzeinstellungen</h3>
            <div class="cbb-text">Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.</div>
            <div id="cbb-select">
                <div class="cbbs-box essenziell selected">
                    <span class="checkbox"></span>
                    <span>Notwendig</span>
                    <input type="checkbox" name="cookiesGroup[]" value="essenziell" checked readonly>
                </div>
                <div v-if="statistiken.length > 0" class="cbbs-box can-select">
                    <span class="checkbox" @click="canSelectParent"></span>
                    <span @click="canSelectParent">Statistiken</span>
                    <input type="checkbox" name="cookiesGroup[]" value="statistiken">
                </div>

            </div>
            <div class="cbb-buttons">
                <button id="cbbb-button-all" class="cbbb-button" @click.prevent="buttonSave('all')">
                    {{ moreToAccept ? 'Alle Akzeptieren' : 'Speichern' }}
                </button>
                <button v-if="moreToAccept" id="cbbb-button-save" class="cbbb-button" @click.prevent="buttonSave('selected')">Speichern</button>
            </div>
            <div id="cbb-indu" @click="openMoreCookiesInfo">
                <span class="open">Individuelle Datenschutzeinstellungen anzeigen</span>
                <span class="closee">Individuelle Datenschutzeinstellungen ausblenden</span>
            </div>
            <div id="cbb-fullinfo" ref="fullinfo">
                <div class="ccbfi-box">
                    <div class="ccbfib-title">Notwendig</div>
                    <div class="ccbfib-desc">Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese Cookies nicht richtig funktionieren.</div>
                    <!-- <div class="ccbfib-button" ><span>Cookie-Informationen anzeigen</span></div> -->
                    <div class="ccbfib-cookies" >
                        <div v-for="item in essenziell" :key="item.slog">
                            <div>
                                <div style="line-height:25px;">Akzeptieren</div>
                                <div class="con-cookie essenziell selected">
                                    <span class="checkbox"></span>
                                    <input type="checkbox" name="cookiesGroup[essenziell][]" :value="item.slog" checked readonly>
                                </div>
                            </div>
                            <div>
                                <div>Name</div>
                                <div>{{ item.name }}</div>
                            </div>
                            <div>
                                <div>Anbieter</div>
                                <div>{{ item.provider }}</div>
                            </div>
                            <div class="cookies-info-row">
                                <div>Cookies</div>
                                <div class="cookies">
                                    <div class="one-cookies-info" v-for="cookie in item.cookies" :key="cookie['cookie-name']">
                                        <div>
                                            <div>Cookie Name</div>
                                            <div>{{ cookie['cookie-name'] }}</div>
                                        </div>
                                        <div>
                                            <div>Cookie Laufzeit</div>
                                            <div>{{ cookie['cookie-time'] }}</div>
                                        </div>
                                        <div>
                                            <div>Zweck</div>
                                            <div>{{ cookie.desc }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ccbfi-box" v-if="statistiken.length > 0 ">
                    <div class="ccbfib-title">Statistiken</div>
                    <div class="ccbfib-desc">Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.</div>
                    <!-- <div class="ccbfib-button" ><span>Cookie-Informationen anzeigen</span></div> -->
                    <div class="ccbfib-cookies" >
                        <div v-for="item in statistiken" :key="item.slog">
                            <div>
                                <div style="line-height:25px;">Akzeptieren</div>
                                <div class="con-cookie can-select">
                                    <span class="checkbox" @click="canSelectChildren"></span>
                                    <input type="checkbox" name="cookiesGroup[statistiken][]" :value="item.slog" >
                                </div>
                            </div>
                            <div>
                                <div>Name</div>
                                <div>{{ item.name }}</div>
                            </div>
                            <div>
                                <div>Anbieter</div>
                                <div>{{ item.provider }}</div>
                            </div>
                            <div class="cookies-info-row">
                                <div>Cookies</div>
                                <div class="cookies">
                                    <div class="one-cookies-info" v-for="cookie in item.cookies" :key="cookie['cookie-name']">
                                        <div>
                                            <div>Cookie Name</div>
                                            <div>{{ cookie['cookie-name'] }}</div>
                                        </div>
                                        <div>
                                            <div>Cookie Laufzeit</div>
                                            <div>{{ cookie['cookie-time'] }}</div>
                                        </div>
                                        <div>
                                            <div>Zweck</div>
                                            <div>{{ cookie.desc }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div id="cbb-more">

				<span v-if="einstellungen.datenschutz.show">
                    <a :href="einstellungen.datenschutz.url">Datenschutzerklärung</a>
                </span>

                <span v-if="einstellungen.impressum.show">
                    <a :href="einstellungen.impressum.url">Impressum</a>
                </span>

            </div>
        </form>
    </div>
</template>

<script>
    //import { mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'CookiesComponent',
        data(){
            return {
                einstellungen: {
                    datenschutz : {
                        show: false,
                        url: '/datenschutz/'
                    },
                    impressum: {
                        show: false,
                        url: '/impressum/'
                    },
                },
                essenziell: [
                    {
                        name: 'Website Einstellungen',
                        slog: 'website',
                        provider: 'Eigentümer dieser Website',
                        url: 'https://policies.google.com/privacy',
                        cookies: [
                            {
                                'cookie-name': 'cookie-banner',
                                'cookie-time': '1 Jahr',
                                'desc': 'Speichert den Zustimmungsstatus des Benutzers für Cookies auf der aktuellen Domäne.'
                            },
                            {
                                'cookie-name': 'age-acc',
                                'cookie-time': 'localstorage',
                                'desc': 'Um diese Website betreten zu können musst Du bestätigen, dass Du mindestens 18 Jahre alt bist.'
                            }
                        ]
                    }
                ],
                 statistiken: [
                //     {
                //         name: 'Google Analytics',
                //         slog: 'google-analytics',
                //         provider: 'Google',
                //         url: 'https://policies.google.com/privacy',
                //         cookies: [
                //             {
                //                 'cookie-name': '_ga',
                //                 'cookie-time': '2 Jahre',
                //                 'desc': 'Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.'
                //             },
                //             {
                //                 'cookie-name': '_gat',
                //                 'cookie-time': '1 Tag',
                //                 'desc': 'Wird von Google Analytics verwendet, um die Anforderungsrate einzuschränken'
                //             },
                //             {
                //                 'cookie-name': '_gid',
                //                 'cookie-time': '1 Tag',
                //                 'desc': 'Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.'
                //             }
                //         ]
                //     }
                ]
            }
        },
        mounted() {

        },
        computed: {
            moreToAccept(){
                return this.statistiken.length > 0 ? true : false;
            },
            isCookiesAcc(){
                return this.getCookie('cookie-banner') ? true : false;
            }
        },
        methods: {
            openMoreCookiesInfo(){
                const nameClass = 'opened';
                const fullinfo = this.$refs.cookieBanner;
                fullinfo.classList.contains('opened') ? fullinfo.classList.remove( nameClass ) : fullinfo.classList.add( nameClass );
            },
            canSelectParent( event ){
                const selectedClass = 'selected';
                const _this = event.target.parentNode;
                const groupName = _this.children[2].value;
                const _childrenArray = document.querySelectorAll(`input[name="cookiesGroup[${groupName}][]"]`);

                if( _this.classList.contains(selectedClass) ){
                    _this.classList.remove(selectedClass);

                    _childrenArray.forEach( (element) => {
                        element.checked = false;
                        element.parentNode.classList.remove(selectedClass);
                    });

                } else {
                    _this.classList.add(selectedClass);

                    _childrenArray.forEach( (element) => {
                        element.checked = true;
                        element.parentNode.classList.add(selectedClass);
                    });
                }

            },
            canSelectChildren( event ){
                const selectedClass = 'selected';
                const _this = event.target.parentNode;
                const _thisChild = _this.children[1];
                const _thisControlGroupName = _thisChild.getAttribute('name').slice( 13, -3 );
                const _thisControlGroup = document.querySelector(`input[name="cookiesGroup[]"][value="${_thisControlGroupName}"]`);

                if( _this.classList.contains(selectedClass) ) {

                    _thisControlGroup.parentNode.classList.remove(selectedClass);
                    _this.classList.remove(selectedClass);
                    _thisChild.checked = false;

                } else {
                    let allSelected = true;

                    _thisChild.checked = true;
                    _this.classList.add(selectedClass);

                    document.querySelectorAll(`input[name="cookiesGroup[${_thisControlGroupName}][]"]`).forEach( element => {
                        if( element.checked === false ){
                            allSelected = false;
                        }
                    })

                    if( allSelected ){
                        _thisControlGroup.parentNode.classList.add(selectedClass);
                    }

                }
            },
            buttonSaveSelected(){

            },
            buttonSave( type ){
                let array = {}
                const notwendigArray = document.querySelectorAll(`input[type="checkbox"][name="cookiesGroup[essenziell][]`);
                const statistikenArray = document.querySelectorAll(`input[type="checkbox"][name="cookiesGroup[statistiken][]`);


                if( notwendigArray.length > 0 ){
                    array['essenziell'] = {};
                    notwendigArray.forEach( element => {
                        if( type === 'selected' && element.checked ){
                            array['essenziell'][element.value] = true;
                        }else if ( type === 'all'){
                            array['essenziell'][element.value] = true;
                        }
                    })
                }

                if( statistikenArray.length > 0 ){
                    array['statistiken'] = {};
                    statistikenArray.forEach( element => {
                        if( type === 'selected' && element.checked ){
                            array['statistiken'][element.value] = true;
                        }else if ( type === 'all'){
                            array['statistiken'][element.value] = true;
                        }
                    })
                }

                this.closeBox();

                this.setCookie( 'cookie-banner', JSON.stringify(array) , 365 );

            },
            closeBox(){
                document.querySelector('#cookies-banner-overlay').remove();
            },
            setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            getCookie(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for(var i = 0; i <ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        }
    }
</script>

<style lang="less" scoped>

    @themeColor1: #830d05;
    @themeColor2: #e6e6e6;

    @checkboxSize: 22px;

    @textSize: 13px;
    @textColor: #3c3c3b;

    @accAllColor: @themeColor1;
    @accColor: @themeColor2;



    #cookies-banner-overlay{
        position: fixed;
        top: 0;
        left: 0px;
        height: 0;
        width: 100%;
        height: 100%;

        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;

        justify-content: center;

        background: rgba(0,0,0, 0.6);

        z-index: 10000;

    }

    #cookies-banner-box{
        position: absolute;
        top: 0;

        width: 100%;
        max-width: 450px;

        background: #fff;

        padding: 40px 25px;

        color: @textColor;

        transition: 0.3s;

        &.opened{
            max-width: 768px;
            max-height: 70vh;

            overflow-y: auto;



            #cbb-fullinfo{
                display: block;
            }

            #cbb-indu{

                .open{ display: none; }
                .closee{
                    display: inline;
                }
            }
        }
    }

    #cbb-title{
        font-size: 20px;
        text-align: center;

        margin: 0;
        padding-bottom: 25px;


    }

    .cbb-text{
        font-size: @textSize;

        text-align: justify;
    }

    #cbb-select{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;

        justify-content: space-between;

        padding: 25px 0px;



        .cbbs-box{
            display: flex;
            align-items:center;

            font-size: @textSize + 1px;
            line-height: 1;

            &.can-select{
                cursor: pointer;
            }

            &.essenziell{

                .checkbox{
                    border-color: darken(@accColor, 10%);

                    &::after{
                        background-color: darken(@accColor, 10%);
                    }
                }

            }

            &.selected{
                .checkbox{

                    &::after{
                        opacity: 1;
                    }
                }
            }

            .checkbox{
                display: inline-block;

                width: @checkboxSize;
                height: @checkboxSize;

                border: 2px solid @themeColor1;

                margin-right: 5px;

                &::after{
                    display: inline-block;

                    opacity: 0;

                    content: ' ';

                    width: 100%;
                    height: 100%;

                    background: url(../assets/images/checked.png) center center no-repeat @themeColor1;
                    background-size: 100%;

                    border: 1px solid #fff;

                    transition: 0.2s;
                }

            }

            input{
                position: absolute;
                opacity: 0;
                z-index: -1;
            }

        }


    }

    #cbb-fullinfo{
        margin-top: 15px;
        display: none;

        .ccbfi-box{
            padding: 15px;
            margin-bottom: 10px;

            background: @themeColor2;

            &:last-child{
                margin-bottom: 0;
            }

            .ccbfib-title{
                padding-bottom: 8px;

                font-size: @textSize + 1px;
                font-weight: 600;
            }

            .ccbfib-desc{
                font-size: @textSize - 1px;

                text-align: justify;
            }

            .ccbfib-button{
                text-align: center;

                span{
                    font-size: @textSize - 2px;
                    color: @themeColor1;

                    cursor: pointer;
                }
            }

            .ccbfib-cookies{

                margin-top: 10px;


                .con-cookie{

                    &.can-select{
                        cursor: pointer;
                    }

                    &.essenziell{

                        .checkbox{
                            border-color: darken(@accColor, 10%);

                            &::after{
                                background-color: darken(@accColor, 10%);
                            }
                        }

                    }

                    &.selected{
                        .checkbox{

                            &::after{
                                opacity: 1;
                            }
                        }
                    }

                    .checkbox{
                        display: inline-block;

                        width: @checkboxSize;
                        height: @checkboxSize;

                        border: 2px solid @themeColor1;

                        margin-right: 5px;

                        &::after{
                            display: inline-block;

                            opacity: 0;

                            content: ' ';

                            width: 100%;
                            height: 100%;

                            background: url(../assets/images/checked.png) center center no-repeat @themeColor1;
                            background-size: 100%;

                            border: 1px solid #fff;

                            transition: 0.2s;
                        }

                    }

                    input{
                        position: absolute;
                        opacity: 0;
                        z-index: -1;
                    }

                }

                > div {
                    margin-bottom: 10px;
                    > div {
                        padding: 3px 5px;
                        background: #fff;

                        display: flex;
                        align-items: flex-start;

                        width: 100%;

                        border-bottom: 1px solid @themeColor2;

                        &:last-child{
                            border-bottom: none;
                        }

                        > div {
                            width: 65%;


                            font-size: @textSize - 2px;

                            &:first-child{
                                width: 35%;
                            }

                            > div {

                                padding: 7px 0px;

                                border-bottom: 1px solid @themeColor2;

                                &:last-child{
                                    border-bottom: none;
                                }

                                > div {
                                    padding: 2px 0px;

                                    display: inline-flex;
                                    align-items: flex-start;

                                    width: 100%;

                                    > div {

                                        width: 50%;
                                        display: inline-block;
                                    }

                                }

                            }


                        }
                    }

                }
            }
        }
    }

    #cbb-indu{
        margin-top: 15px;

        text-align: center;
        font-size: @textSize - 2px;

        color: @themeColor1;

        cursor: pointer;

        .closee{
            display: none;
        }
    }

    .cbbb-button{
        width: 100%;

        padding-top: 15px;
        padding-bottom: 15px;
        border: 0px;

        font-size: 15px;
        font-weight: 400;

        background: @accColor;

        color: @textColor;

        cursor: pointer;

        &:hover{
            background: darken(@accColor, 5%);
        }

        &#cbbb-button-save{
            margin-top: 10px;
        }

        &#cbbb-button-all{
            background: @accAllColor;

            color:#fff;

            &:hover{
                background: darken(@accAllColor, 5%);
            }
        }
    }

    #cbb-more{
        text-align: center;

        margin-top: 10px;

        span{
            &::after{
                content: '|';
                padding: 0px 5px;

                font-size: @textSize - 3px;
            }

            &:last-child{
                &::after{
                    display: none;
                }
            }
        }

        a{
            font-size: @textSize - 3px;
        }
    }


    @media( max-width: 500px ) {

        #cbb-title{
            font-size: 16px;
            padding-bottom: 15px;
        }

        .cbb-text{
            font-size: @textSize - 2px;
            text-align: justify;
        }

        #cbb-select .cbbs-box {

            font-size: @textSize - 1px;

        }

        .cbbb-button{
            font-size: 13px;
        }

        .cookies-info-row{
            display: block 	!important;
            > div {
                width: 100% !important;
            }
        }

    }

</style>
