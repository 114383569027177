<template>
    <div id="langSwitcher">
        <div class="select-lang lang-de">
            <router-link :to="{ name: 'Start'}"><img src="../assets/images/de.png" alt="Deutsch"></router-link>
<!--            <span>Deutsch</span>-->
        </div>

        <div class="select-lang lang-gb">
<!--            <span>English</span>-->
            <router-link :to="{ name: 'StartENG'}"><img src="../assets/images/gb.png" alt="English"></router-link>

        </div>
    </div>
</template>

<script>
    export default {
        name: "LangSwitcherComponent"
    }
</script>

<style lang="less" scoped>
    #langSwitcher{
        position: absolute;
        width: 159px;
        top: 6px;
        right: 50px;
        z-index: 99;

        color:#fff;

        display: flex;
        justify-content: flex-end;

        .select-lang {

            display: flex;
            align-items: center;

            &.lang-gb{
                img{margin-left: 10px;}


                span{
                    margin-left: 0;
                    margin-right: 5px;

                }
            }

            img{
                height: 28px;
                width: auto;
            }

            span{
                font-size: 12px;
                margin-left: 5px;

                text-transform: uppercase;

            }
        }
    }
</style>
