const MENU_STATE = {
    menu: {
        opened: false,
    }
};
//
const MENU_MUTATIONS = {
    openMenu: ( { menu } ) => {
        menu.opened = true;
    },
    closeMenu: ( { menu } ) => {
        menu.opened = false;
    },
};

const MENU_ACTIONS = {

};

const MENU_GETTERS = {
    isMenuOpen: state => state.menu.opened,

};


export { MENU_STATE, MENU_GETTERS, MENU_ACTIONS, MENU_MUTATIONS }

