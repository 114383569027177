import Vue from 'vue'
import Vuex from 'vuex'

import { AGEQUESTION_STATE, AGEQUESTION_MUTATIONS, AGEQUESTION_ACTIONS, AGEQUESTION_GETTERS} from './ageQuestion';
import { MENU_STATE, MENU_MUTATIONS, MENU_ACTIONS, MENU_GETTERS } from './menu';
import { LOADER_STATE, LOADER_MUTATIONS, LOADER_ACTIONS, LOADER_GETTERS } from './loader';
import { GALLERY_STATE, GALLERY_MUTATIONS, GALLERY_ACTIONS, GALLERY_GETTERS } from './gallery';
import { LANG_STATE, LANG_MUTATIONS, LANG_ACTIONS, LANG_GETTERS } from './lang'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...AGEQUESTION_STATE,
    ...MENU_STATE,
    ...LOADER_STATE,
    ...GALLERY_STATE,
    ...LANG_STATE
  },
  mutations: {
    ...AGEQUESTION_MUTATIONS,
    ...MENU_MUTATIONS,
    ...LOADER_MUTATIONS,
    ...GALLERY_MUTATIONS,
    ...LANG_MUTATIONS
  },
  actions: {
    ...AGEQUESTION_ACTIONS,
    ...MENU_ACTIONS,
    ...LOADER_ACTIONS,
    ...GALLERY_ACTIONS,
    ...LANG_ACTIONS
  },
  modules: {
  },
  getters: {
    ...AGEQUESTION_GETTERS,
    ...MENU_GETTERS,
    ...LOADER_GETTERS,
    ...GALLERY_GETTERS,
    ...LANG_GETTERS
  }
})
