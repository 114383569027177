const routes = [
    {
        path: '/en',
        name: 'StartENG',
        component: () => import(/* webpackChunkName: "store" */ '../views/StartView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/store',
        name: 'StoreENG',
        component: () => import(/* webpackChunkName: "store" */ '../views/StoreView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/about',
        name: 'AboutENG',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/sprechstunde',
        name: 'SprechstundeENG',
        component: () => import(/* webpackChunkName: "about" */ '../views/SprechstundeView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/events',
        name: 'EventsENG',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/EventsView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/downloads',
        name: 'DownloadsENG',
        component: () => import(/* webpackChunkName: "about" */ '../views/DownloadsView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/impressum',
        name: 'ImpressumENG',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/ImpressumView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/datenschutz',
        name: 'DatenschutzENG',
        component: () => import(/* webpackChunkName: "datenschutz" */ '../views/DatenschutzView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/buy',
        name: 'BuyENG',
        component: () => import(/* webpackChunkName: "about" */ '../views/BuyView.vue'),
        meta: { lang: 'en' }
    },
    {
        path: '/en/kontakt',
        name: 'KontaktENG',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/KontaktView.vue'),
        meta: { lang: 'en' }
    },
];

export default routes;
//
